import React from "react"
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function SolutionObesity() {
	
	return (
		<Article
			title='Right-sizing your weight'
			takeaway_title='Make following changes to lose excess weight'
			page_id='Solution - Obesity'
			read_time='10 mins'
			author='Brij Bhushan'
			image1='/before-after.jpg'
			image1_description='Using these diet & lifestyle changes, I lost 22 kgs'
			image2='/healthy_digestion.jpg'
			image2_description='Healthy digestion is key to weight loss'
			toggle_article='Blog'
			toggle_url='/article/blog_obesity'
			related_article='Blog : Obesity'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
				<div className='article_container'>
					<p>
						BMI (Body Mass Index) is a good indicator of healthy-weight of a person. Among Indians, if BMI is greater than 22.9 then one can be considered overweight <span className="super">[1]</span>. The following table indicates the range of healthy-weight with respect to height of an individual:
					</p>
					<div className='bmi_table' >
						<div className='height'>
							<h5>5'1"</h5>
							<h5>5'2"</h5>
							<h5>5'3"</h5>
							<h5>5'4"</h5>
							<h5>5'5"</h5>
							<h5>5'6"</h5>
						</div>
						<div className='weight'>
							<h5>49 +/- 6 kg</h5>
							<h5>51 +/- 6 kg</h5>
							<h5>52 +/- 6 kg</h5>
							<h5>54 +/- 6 kg</h5>
							<h5>56 +/- 7 kg</h5>
							<h5>57 +/- 7 kg</h5>
						</div>
						<div className='height'>
							<h5>5'7"</h5>
							<h5>5'8"</h5>
							<h5>5'9"</h5>
							<h5>5'10"</h5>
							<h5>5'11"</h5>
							<h5>6'</h5>
						</div>
						<div className='weight'>
							<h5>59 +/- 7 kg</h5>
							<h5>61 +/- 7 kg</h5>
							<h5>63 +/- 8 kg</h5>
							<h5>65 +/- 8 kg</h5>
							<h5>67 +/- 8 kg</h5>
							<h5>68 +/- 8 kg</h5>
						</div>
					</div>
					<p>
						(WHO refers to a BMI greater than 24.9 as overweight <span className="super">[2]</span>. However, it has left to governments of respective Asian countries to define their guidelines for BMI.)
					</p>
					<h3 className='article_heading'>What’s wrong with being overweight?</h3>
					<p>
						Being overweight is a symptom of bad health, especially when the weight is concentrated in the trunk area. It indicates inefficiency of the digestive system to digest food properly that impacts absorption of nutrition into the body. Gaining excess weight can eventually lead to various problems like low-immunity, high cholesterol, high blood pressure, diabetes, heart diseases, weak eyes, weakness of muscles and bones. Low-immunity results in high frequency of catching cold, flu-like infections and other infections like different kinds of face/body acne. Therefore, if one can maintain weight within a healthy range then one can avoid many health problems. <span className="super">[3]</span>
					</p>
					<h3 className='article_heading'>How to reduce weight?</h3>
					<p>
						One becomes overweight due to improper diet and unhealthy lifestyle. Therefore, correcting both has an important role in losing the excess weight. A good diet is required to stop further accumulation of fat in the body and to ensure good nourishment of the body. In lifestyle, exercise and sufficient sleep are important to burn the accumulated excess fat in the body. Both the aspects are discussed in detail in the subsequent sections.
					</p>
					<h3 className='article_heading'>1. No refined white sugar</h3>
					<p>
						When one consumes refined white sugar, it converts into a high amount of glucose during digestion. Human body can easily absorb the sugar from natural sources like fruits, grains, etc. but to absorb the glucose generated from refined white sugar it requires high secretion of insulin from pancreas. This leads to a condition called Insulin Resistance. Consequently, the excess sugar is converted to fat and stored in the trunk area. Therefore, the first key to lose extra weight is to stop consuming refined sugar in any form. This means not eating any food item which has sugar in the ingredients, like biscuits/cookies, cakes, soft drinks, ice-creams, chocolates, candies, etc. However, one can eat sweet fruits like mangoes, oranges, dates, etc. but in moderation. One can also consume food items prepared with jaggery or honey but in moderation. <span className="super">[4], [5.ch-10]</span>
					</p>
					<h3 className='article_heading'>2. Not drinking water before & after meals</h3>
					<p>
						If one drinks water just before a meal, then it interferes with the appetite and that can result in poor digestion. Similarly, if one drinks water immediately after a meal then it dilutes digestive fluids in the stomach and they are no longer strong enough to digest the food completely. This can result in moving the semi-digested food towards the small intestine. That puts an extra load of digestion on the liver. As a consequence, it can result in accumulation of fat on the liver and in the trunk area.
					</p>
					<p>
						However, one can drink a few gulps of water while having a meal, if food is hard, dry or one experiences coughing, etc. Preferably, one should have curries with added water as part of the meal, like lentil curry. Consuming excess water before, during or after a meal causes indigestion. One can drink water 30 minutes before a meal and 1 hour after a meal. <span className="super">[8]</span>
					</p>
					<h3 className='article_heading'>3. No snacking between meals</h3>
					<p>
						When we eat a meal, it takes roughly 4 to 6 hours for the body to digest it. If we eat any snack during that period, it results in moving the partially digested food towards the intestines in order to accommodate the snack in the stomach. In other words, the meal is not digested properly. This also puts an extra load of digestion on the liver and as a consequence results in accumulation of fat on the liver and in the trunk area.
					</p>
					<p>
						Best practice is not to eat anything till at least 4 hours have elapsed since the last meal. After 4 hours if one has to eat, prefer consuming fresh fruits or vegetable soup, which are quick and easy to digest. <span className="super">[5.ch-8]</span>
					</p>
					<h3 className='article_heading'>4. Properly chewing the food</h3>
					<p>
						It is said that one should “Drink the food and eat the drink”. This means that one should chew the food so much that it flows down the throat with saliva, without any effort to swallow it and have a drink so slow that sufficient saliva gets mixed with it before it goes down the throat.
					</p>
					<p>
						The digestive fluids of the stomach are mostly acidic and the saliva is alkaline. If sufficient saliva is mixed with the food then a right balance is maintained in the digestive system, which results in efficiency in digestion. Moreover, when food is chewed properly then it is easily digested in the stomach and when it reaches the large intestine there is nothing much left for the gut bacteria to decompose. In short, chewing the food properly solves two problems; first better digestion & hence less fat accumulation, second less gastric issues. <span className="super">[5.ch-8]</span>
					</p>
					<h3 className='article_heading'>5. No alcohol</h3>
					<p>
						Alcohol doesn’t have any fibre, therefore the stomach can’t digest it. If an alcoholic beverage is consumed in moderation, its alcohol content is less than 15% and it is consumed after a meal, then the food slows down its flow outside the stomach. This can protect the liver from ill-effects of the alcohol. Otherwise all the alcohol simply flows out of the stomach towards the small intestine and that creates additional stress on the liver. This results in fat accumulation, fatty liver and obesity. <span className="super">[5.ch-8]</span>
					</p>
					<h3 className='article_heading'>6. No fruit juice</h3>
					<p>
						When we eat fruit, it goes to the stomach along with its fibre and the stomach digests it properly. However, when we drink fruit juice it is quickly passed on to the liver by the stomach because the stomach fails to digest it in absence of fibre. Just like alcohol, when the liver digests the juice, then it gets converted into fat, resulting in fatty liver and obesity. <span className="super">[6]</span>
					</p>
					<p>
						However, in the morning when one has an empty stomach, fresh juice can be consumed along with its fibre. In the morning, the digestive system is ready to take extra load. Also, it should be consumed very slowly sip-by-sip, so that sufficient saliva is mixed with it and it is digested slowly instead of overwhelming the digestive system. <span className="super">[7]</span>
					</p>
					<h3 className='article_heading'>7. Good sleep at night</h3>
					<p>
						When one does not get adequate sleep at night, then one feels hungry more often. This results in eating food in more quantity and more snacking. This results in poor digestion by the stomach and eventually more fat accumulation. Sleep provide time to the body for regeneration. <span className="super">[5.ch-8]</span>
					</p>
					<h3 className='article_heading'>8. Regular exercise</h3>
					<p>
						If one does sufficient exercise, then digestion works better. Good digestion results in less fat accumulation in the body. Exercise also helps in good blood circulation throughout the body. Morning is the best time to exercise. Also, taking a walk after dinner helps in digestion. <span className="super">[5.ch-8]</span>
					</p>
					<p>
						A minimum of 30 minutes of walk in a day is mandatory for humans, to maintain a healthy homeostasis (for maintaining healthy parameters of the body, such as body temperature, fluid balance and blood circulation).
					</p>
					<h3 className='article_heading'>Conclusion</h3>
					<p>
						These 8 changes in diet and lifestyle will stop fat accumulation in the body. Slowly and gradually, the body will consume the accumulated extra fat. This will continue till the body strikes a balance between the fat it needs with respect to the new lifestyle. This approach doesn’t require starving yourself. The key is to find the right healthy replacements for the unhealthy items in the diet such that the new lifestyle is sustainable. There is no need for excessive exercise either. Just a basic exercise routine is required (daily or alternate days), which one can easily absorb in his/her lifestyle. The emphasis should be to make changes in diet and lifestyle in such a way that those can be sustained for a long period.
					</p>
					<p>
						PS: With these changes in diet and lifestyle, slowly and gradually I lost 22 kgs over a period of 3 years and after that I am stable at a weight of 66 kg from last one year (with a height of 5’10”). To learn more about details of my experience click <Link to='/article/blog_obesity'>here</Link>.

					</p>
					<h3 className='article_heading'>References</h3>
					<ol className="reference_list">
						<li>
							Misra A., Chowbey P., Makkar B.M. Consensus statement for diagnosis of obesity, abdominal obesity and the metabolic syndrome for Asian Indians and recommendations for physical activity, medical and surgical management. J Assoc Physicians India. 2009;57:163–170. <Link to='https://www.researchgate.net/publication/26652681_Consensus_Statement_for_Diagnosis_of_Obesity_Abdominal_Obesity_and_the_Metabolic_Syndrome_for_Asian_Indians_and_Recommendations_for_Physical_Activity_Medical_and_Surgical_Management'>https://www.researchgate.net/publication/26652681_Consensus_Statement_for_Diagnosis_of_Obesity_Abdominal_Obesity_and_the_Metabolic_Syndrome_for_Asian_Indians_and_Recommendations_for_Physical_Activity_Medical_and_Surgical_Management</Link>
						</li>
						<li>
							BMI Calculator. National Institute of Health, National Heart, Lung & Blood Institute,  U.S. Department of Health & Human Services. <Link to='https://www.nhlbi.nih.gov/health/educational/lose_wt/BMI/bmicalc.htm'>https://www.nhlbi.nih.gov/health/educational/lose_wt/BMI/bmicalc.htm</Link>
						</li>
						<li>
							Su Youn Nam. Obesity-Related Digestive Diseases and Their Pathophysiology. Gut and Liver. 2017;11(3): 323-334. <Link to='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5417774/'>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5417774/</Link>
						</li>
						<li>
							Kathleen Doheny. Sugar and Excess Weight: Evidence Mounts. WebMD, Diet & Weight Management. 2013. <Link to='https://www.webmd.com/diet/news/20130114/sugar-excess-weight#1'>https://www.webmd.com/diet/news/20130114/sugar-excess-weight#1</Link>
						</li>
						<li>
							Vagbhata’s Astanga Hrdayam, Volume-1, Chowkhamba Krishnadas Academy, Varanasi, 2019. ( [5.ch-8] refers to Chapter-8 )
						</li>
						<li>
							Joseph M Pappachan, Shithu Babu, Babu Krishnan, Nishal C Ravindran. Non-alcoholic Fatty Liver Disease: A Clinical Update. Journal of Clinical and Translational Hepatology. Xia & He Publishing. 2017;5(4):384-393. <Link to='https://www.xiahepublishing.com/ArticleFullText.aspx?sid=2&jid=1&id=10.14218%2fJCTH.2017.00013'>https://www.xiahepublishing.com/ArticleFullText.aspx?sid=2&jid=1&id=10.14218%2fJCTH.2017.00013</Link>
						</li>
						<li>
							Jo Levin. Sugar explained. BBC Good Food. 2019. <Link to='https://www.bbcgoodfood.com/howto/guide/sugar-explained'>https://www.bbcgoodfood.com/howto/guide/sugar-explained</Link>
						</li>
						<li>
							Jagdev Singh. When to Drink Water: Ayurvedic Rules for Drinking Water. Ayur Times, Preventive Ayurveda. 2019. <Link to='https://www.ayurtimes.com/ayurvedic-rules-for-drinking-water/'>https://www.ayurtimes.com/ayurvedic-rules-for-drinking-water/</Link>
						</li>
					</ol>
				</div>
			
			
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: "Don't consume sugar & products that contain sugar",
	},
	{
		takeaway: "Don't drink water before & after meals",
	},
	{
		takeaway: "Don't eat snacks between meals",
	},
	{
		takeaway: "Chew your food properly",
	},
	{
		takeaway: "Don't consume alcohol",
	},
	{
		takeaway: "Don't drink fruit juice",
	},
	{
		takeaway: "Have sufficient sleep at night",
	},
	{
		takeaway: "Exercise regularly",
	},
];

const RelatedArticles = [
	{
		title: 'Blog - Obesity',
		path: '/article/blog_obesity',
	},
];

